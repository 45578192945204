import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HeaderTransmitService } from 'src/app/services/transmit.service';
import { assetURL, environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { AlertService } from 'src/app/services/alert.service';
import { SsoService } from 'src/app/services/application.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { TabService } from 'src/app/services/tab.service';
import { TransmitUtils } from 'src/app/services/transmit-utils';
import { ApplicationService } from 'src/app/services/app.service';

@Component({
  selector: 'app-view-more-notifications-modal',
  templateUrl: './view-more-notifications-modal.component.html',
  styleUrls: ['./view-more-notifications-modal.component.css']
})
export class ViewMoreNotificationsModalComponent implements OnInit, AfterViewInit {
  @Input() notificationData: any;
  @Input() selectedCredData: any;
  public samlUrl: string = '';
  public samlInfo: string = '';
  public tabName: string = '';
  public currentCompanyId: any;
  public universalId: string = '';
  public modalDisplayName: string = '';
  public modalIsRegistered: boolean = false;
  private legacyEmail: string = '';
  private personaDetailsExtesnionJson: {} | undefined;
  private openedTab: Window | null = null;
  private notificationsModalRef!: NgbModalRef;
  @ViewChild('modal') tabModal!: TemplateRef<any>;
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(private ngbModal: NgbModal,
    private transmitService: HeaderTransmitService,
    private alertService: AlertService,
    private applicationService: SsoService,
    private spinnerService: SpinnerService,
    private tabService: TabService,
    private transmitUtils: TransmitUtils,
    private appService: ApplicationService,
    private activeModal: NgbActiveModal
  ) {
  }

  get assetURL() {
    return assetURL;
  }

  ngOnInit(): void {
    this.universalId = sessionStorage.getItem('universalId') ?? '';
    console.log(this.notificationData);
  }

  ngAfterViewInit(): void {
    this.transmitService.initializeTransmit(environment.transmitUrl);
  }

  closeModal() {
    this.ngbModal.dismissAll();
  }

  openApplication(notification: any) {
    // const openAOEvent = new CustomEvent("open-access-optima", {
    //   detail: { from: "btl-application", ssoIdData: this.selectedCredData }
    // });
    // dispatchEvent(openAOEvent);
    this.login(this.selectedCredData, notification);
    this.activeModal.close();
  }

  login(item:any, notification: any) {
    let userId = item.userId;
    let compId = item.companyId;
    let appName = item.applicationName;
    var appSpecificData = this.applicationService.getConfigAppsData(appName);
    let displayName = appSpecificData?.displayName;
    let isRegistered = appSpecificData?.isRegistered;
    let accountLogin = item.ssoId;
    let personalDetailExtension = item.personaDetailsExtension;
    let webAppId = this.applicationService.getTransmitJourney(item.applicationName);
    console.log("chckng if AO btn event is triggere",item)
    if (personalDetailExtension === undefined) {
      this.legacyEmail = '';
    } else {
      this.legacyEmail = personalDetailExtension.legacyEmailAddress;
      this.personaDetailsExtesnionJson = personalDetailExtension.personaDetailsExtesnionJson;
      if ((typeof this.personaDetailsExtesnionJson) === 'string') {
        this.personaDetailsExtesnionJson = JSON.parse(personalDetailExtension.personaDetailsExtesnionJson);
      }
    }
    this.alertService.hide();
    this.spinnerService.clearText();
    // TODO actual login!
    this.appService.updateLastLogin(accountLogin, this.universalId);
    // Last Login Details Update to DB

    const samlInfo: any = this.applicationService.getSamlInfo(appName);
    this.samlUrl = samlInfo.samlActionUrl;
    this.tabName = appName;
    this.samlInfo = 'data';     // TODO: change it!
    const isOpen = this.tabService.isAppTabOpen(appName);

    if (isOpen) {
      // open dialog message to close tab
      this.openMessageDialog(displayName, isRegistered);
    } else {

      const preprodValue = sessionStorage.getItem('preprod');
      let journeyName = 'ping_dropoff';

      // seperate route for non SAML flow
      if (appSpecificData?.usesHeaderInjection) {
        const url = new URL(this.applicationService.getConfigAppsData(appName)?.baseUrls?.login)
        console.log(url)
        const hintValue = userId + "_" + (compId ? compId : '')
        console.log(hintValue);
        let params = new URLSearchParams;
        params.append('hint', hintValue);
        const redirectUrl = `${url}?${params.toString()}`;
        var newTab = window.open(appSpecificData?.baseUrls?.logout)
        setTimeout(function () {
          if (newTab && !newTab.closed) {
            newTab.close();
            window.open(redirectUrl)
          }
        }, 2000);

      } else if (appSpecificData?.isDeeplinkEnabled) {
        this.applicationService.getDeepLinkURL(userId, compId, appName, notification, appSpecificData).then(deeplinkUrl => {
          this.openTab(deeplinkUrl, appName);
        });
      } else {
        // route for SAML flow
        this.transmitService.pingDropOffTransmitInvokePolicy(userId, compId, appName, webAppId, accountLogin, journeyName, this.universalId, this.legacyEmail, preprodValue, this.personaDetailsExtesnionJson).then((res: any) => {
          console.log('token', res._token);
          const tokenInfo = this.getDecodedAccessToken(res._token);

          if (tokenInfo === undefined || tokenInfo === null || tokenInfo === '') {
            this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
            return;
          }

          let ping_ref_id = tokenInfo['ping_ref_id'];
          let ping_login_url = tokenInfo['ping_sso_url'];


          if (ping_login_url === undefined || ping_login_url === null || ping_login_url === '') {
            this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
            return;
          }

          this.openTab(ping_login_url, appName);

        }).catch((error: any) => {

          console.log('error', error);
          if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'error', 'numcode') == '3211') {
            item.disabled = true;
            this.alertService.error(this.transmitUtils.getErrorMessage('3211'));
            setTimeout(() => {window.scrollTo(0, 0)},0);
          } else if (this.getNested(error, '_data', 'failure_data', 'reason', 'data', 'error', 'numcode') == '3220') {
            item.locked = true;
            this.alertService.error(this.transmitUtils.getErrorMessage('3220'));
            setTimeout(() => {window.scrollTo(0, 0)},0);
          } else {
            this.alertService.error(this.transmitUtils.getErrorMessage('serviceUnavailableTryAgain'));
            setTimeout(() => {window.scrollTo(0, 0)},0);
          }
          // this.service.previousDisableState.next(this.data);
        });
      }
    }
  }

  private openTab(ping_login_url: any, appName: any) {
    if (this.openedTab == null || this.openedTab.closed) {
      this.openedTab = window.open(ping_login_url);
      const appTabComponent: { [key: string]: string; } = {};
      appTabComponent['appName'] = this.tabName;
      appTabComponent['SamlUrl'] = ping_login_url;
      this.tabService.saveAppComponentTab(appName, appTabComponent);
      this.tabService.saveAppTab(appName, this.openedTab);
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch (Error) {
      return null;
    }
  }

  getNested(obj:any, ...args:any) {
    return args.reduce((obj:any, level:any) => obj && obj[level], obj);
  }

  openMessageDialog(displayName:any, isRegistered:any) {
    this.modalDisplayName = displayName;
    this.modalIsRegistered = isRegistered;
    this.notificationsModalRef = this.ngbModal.open(this.tabModal, { size: 'sm', centered: true });
  }

}
