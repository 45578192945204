<div id="headerContainer" class="full-width-header-container">
  <nav class="navbar navbar-expand-lg" id="dashboard-header">
    <span class="navbar-brand fixedMargin flex-shrink-1">
      <img alt="Citizens Commercial Banking" src="{{assetURL}}assets/CitizensCommercial_TM_DeepGreen.svg">
    </span>
    <button id="nav-toggle" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidenav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
    </div>
    <!-- Digital Butler -->
    <div class="navbar-align hide-on-tablet-mobile">
      <ul class="navbar-nav">
        <li class="nav-item">
          <button id="digitalButler" (click)="openUniversalLogin()" cbdata-reason="digital-butler" cbdata-type="link"
            class="btn btn-primary butler-button cbensightenevent">
            <svg class="cbds-c-icon sso-butler" focusable="false">
              <use [attr.href]="assetURL+'assets/cbds-icons-brand.svg#digital-butler'"
                [attr.xlink:href]="assetURL+'assets/cbds-icons-brand.svg#digital-butler'"></use>
            </svg>
            Ask Digital Butler</button>
        </li>
      </ul>

      <ul class="navbar-nav" *ngIf="!isMobileApp && totalNotifications !== 0">
        <li class="nav-item">
          <div class="dropdown float-right">
            <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle cbensightenevent"
              data-bs-toggle="dropdown" data-toggle="dropdown" id="svgBell" type="button"
              cbdata-reason="notifications-header" cbdata-type="button">Notifications
              <app-loading-spinner [displaySpinner]="displaySpinner"></app-loading-spinner>
              <span *ngIf="!displaySpinner && totalNotifications !== 0"
                class="badge-number">{{totalNotifications}}</span>
            </button>

            <div #notifications aria-labelledby="userName" class="dropdown-menu dropdown-menu-notification">

              <div>
                <b *ngIf="notificationsList?.length > 1">Company</b>
                <select *ngIf="notificationsList?.length > 1" name="companyNameSelect"
                  (change)="displayNotifications($event)" [(ngModel)]="selectedCompanyId">
                  <option *ngFor="let creds of notificationsList" [value]="creds.compId">
                    {{creds.compId}}</option>
                </select>
              </div>
              <ul class="notifications-list">
                <ng-container *ngFor="let item of selectedNotifications; let i = index;">
                  <li class="notifications-count cbensightenevent" *ngIf="i < 3" (click)="openApplication(item)"
                    cbdata-reason="notifications-header-login-to-accessoptima" cbdata-type="li">
                    <span *ngIf="item.count !== 0" class="count">{{ item.count }}</span>
                    <p *ngIf="item.count !== 0" class="notification-subject" [innerHTML]="item.subject"></p>
                  </li>
                  <li class="more-notifications" *ngIf="i === 3 && selectedNotifications.length > 3">
                    <a (click)="viewMoreNotifications()" class="view-more">View More</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </li>
      </ul>

      <ul class="navbar-nav" id="desktopUser">
        <li class="nav-item float-right">
          <div class="dropdown float-right">
            <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle"
              data-bs-toggle="dropdown" data-toggle="dropdown" id="userName" type="button">{{ fullName !==
              undefined ?
              fullName : '' }}
              <svg focusable="false" aria-hidden="true" class="cbds-c-icon cbds-c-button__icon chevron-icon">
                <use [attr.href]="assetURL+'assets/cbds-icons-ui.svg#chevron-down'"
                  [attr.xlink:href]="assetURL+'assets/cbds-icons-ui.svg#chevron-down'"></use>
              </svg>
            </button>
            <ul aria-labelledby="userName" class="dropdown-menu dropdown-menu-right">
              <li><a (click)="profile()" (touchend)="profile()" cbdata-reason="dashboard-profile" cbdata-type="link"
                  class="dropdown-item cbensightenevent">Update Profile</a></li>
              <li><a (click)="manageCredentials()" (touchend)="manageCredentials()" cbdata-reason="dashboard-profile"
                  cbdata-type="link" class="dropdown-item cbensightenevent">Add/Remove
                  Services</a>
              </li>
              <li><a (click)="logout()" (touchend)="logout()" cbdata-reason="dashboard-logout" cbdata-type="link"
                  class="dropdown-item cbensightenevent">Sign Out</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>

</div>

<ng-template #modal let-cross="close" let-dismiss="dismiss">
  <app-message-dialog [title]="" [cross]="cross" [dismiss]="dismiss" class="justify-content-center">
    <div class="boldDialogText">You have one active session in
      <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>.
    </div>
    <div>Please, log out from <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>
      and close the tab before accessing with a different credential.</div>
  </app-message-dialog>
</ng-template>

<!-- new application tab container -->
<ng-container #container></ng-container>
<div class="container">
  <div class="row">
    <div class="col-12">
      <app-alert></app-alert>
    </div>
  </div>
</div>
