import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { DynamicComp } from './directives/dynamic-comp.directive';
import { PrivateBankingHeaderComponent } from './components/private-banking-header/private-banking-header.component';
import { HttpClientModule } from '@angular/common/http';
import { ViewMoreNotificationsModalComponent } from './components/view-more-notifications-modal/view-more-notifications-modal.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { TransmitUtils } from './services/transmit-utils';
import { WindowComponent } from './components/window/window.component';
import { AlertComponent } from './components/alert/alert.component';
import { MessageDialogComponent } from './components/message-dialog/message-dialog.component';
import { ModalSpinnerComponent } from './components/modal-spinner/modal-spinner.component';
import { SsoUtils } from './services/sso-utils';
import { TransmitUiHandler } from './services/transmit-ui-handler';
import { UniversalSessionService } from './services/universalSession.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalModule } from '@angular/cdk/portal';
import { CardsHeaderComponent } from './components/cards-header/cards-header.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DynamicComp,
    PrivateBankingHeaderComponent,
    ViewMoreNotificationsModalComponent,
    LoadingSpinnerComponent,
    WindowComponent,
    AlertComponent,
    MessageDialogComponent,
    ModalSpinnerComponent,
    CardsHeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    PortalModule
   ],
  providers: [TransmitUtils, SsoUtils, TransmitUiHandler, UniversalSessionService, NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }
