export const baseUrl = 'https://universalportal-sso.qacitizensbank.com';
export const assetPrefix = "/header-mfe/";
export const assetURL = `${baseUrl}${assetPrefix}`;
export const commercialDigitalGateawayURL = "https://client.qacitizensbank.com";

export const environment = {
  production: false,
  isQA: false,
  isQA2: false,
  isSIT: false,
  isDEV: true,
  salutation: "Hello",
  salutationPrivateBanking: "Welcome",
  aesVariable: '9s8k438599284367',
  logoutUrl: 'https://corporatecard.qacitizensbank.com/login',
  notificationsUrl: `${baseUrl}/notification/v1/notifications/viewList`,
  transmitUrl: `${baseUrl}`,
  univ_apps_url: `${baseUrl}/auth/config/feContext`,
  univ_updateLastLoginDetails: `${baseUrl}/auth/persist/updateLastLoginDate/`,
  univ_defaultLogoutUrl: `${commercialDigitalGateawayURL}/s/`,
  univ_defaultMobileLogoutUrl: `${baseUrl}/sso/uportal/mobile-login`,
  univ_extendSession: `${baseUrl}/auth/extend`,
  universalLoginServiceCase: 'https://client.qacitizensbank.com/s/servicecase',
  universalLoginQuickGuide: 'https://client.qacitizensbank.com/s/article/Universal-Login',
  deeplinkCDNUrl: 'https://universalportal-sso.qacitizensbank.com/content/deeplink/',
  enrollNowUrl: `${baseUrl}/sso/uportal/enroll`,
  univ_alive: {
    tabAliveInterval: 300000,   // millisecs - 5 minutes
    idleTimeout: 900,          // seconds - 15 minutes
    inactiveInterval: 5        // seconds
  },
  contactMessage: {
    contact1: 'Treasury Solutions specialist',
    contact2: 'Client Services',
    phone1: '877-550-5933',
    phone2: '(401-282-1362)',
    email: 'clientservices@mail.client.citizensbank.com',
    hours: '24 hours a day, 7 days a week'
  },
  univ_applications: [
    {
      name: 'accessoptima',
      displayName: 'accessOPTIMA',
      oudAppName: 'MMGPS',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_access_optima',
        samlActionUrl: 'https://9471-sbx.btbanking.com/Shibboleth.sso/SAML2/POST',
        relayState: 'https://9471-tst.btbanking.com/ui/',
        logoutUrl: `${commercialDigitalGateawayURL}/s/`,
        mobileLogoutUrl: 'https://gateway.qacitizenscommercialbanking.com/ccp/accessoptima-mobile.jsp'
      }
    },
    {
      name: 'achieve',
      displayName: 'ACHieve Access',
      oudAppName: 'ACHIEVE',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_achieve_access',
        samlActionUrl: 'https://qaachieveaccess.qacitizenscommercialbanking.com/exchange/sso/login',
        relayState: 'token'
      }
    }, {
      name: 'trade360',
      displayName: 'accessGLOBAL Trade Flow',
      oudAppName: 'GTF',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_trade360'
      }
    },
    {
      name: 'seeburger',
      displayName: 'Citizens File Gateway',
      oudAppName: 'STERLING',
      isRegistered: true,
      isTradeMarked: false,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_seeburger'
      }
    },
    {
      name: 'CASHFLOW',
      displayName: 'Cash Flow Essentials',
      oudAppName: 'CASHFLOW',
      isRegistered: false,
      isTradeMarked: true,
      isActive: true,
      appLoginJson: {
        transmitJourney: 'web_cashflow'
      }
    }
  ],
  univ_treasuryLogin: [
    {
      name: 'accessdeposit',
      displayName: 'accessDEPOSIT',
      isRegistered: true,
      homeURL: 'https://preprod.itms-online.com/citizenscommercialbankingaccessdeposit.aspx',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/accessdeposit.page',
      isDisabled: false
    },
    {
      name: 'accessescrow',
      displayName: 'accessESCROW',
      isRegistered: true,
      homeURL: 'https://sso.qacitizensbank.com/escrow.html',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-liquidity/accessescrow.aspx',
      isDisabled: false
    },
    {
      name: 'accessliquidity',
      displayName: 'accessLIQUIDITY',
      isRegistered: false,
      homeURL: 'https://sso.qacitizensbank.com/LiquidityRedirect.html',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-liquidity/accessliquidity.aspx',
      isDisabled: false
    },
    {
      name: 'accesslockbox',
      displayName: 'accessLOCKBOX',
      isRegistered: true,
      homeURL: 'https://receivablesuat.regulusgroup.com/lockbox/default.htm',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/lockbox.page',
      isDisabled: false
    },
    {
      name: 'commercialcreditcard',
      displayName: 'Commercial Credit Card',
      isRegistered: false,
      homeURL: 'https://www.centresuite.com/Centre/Public/Logon/Index?ReturnUrl=%2fcentre%3fCitizens&Citizens',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/credit-card-and-payment-services.aspx?',
      isDisabled: false
    },
    {
      name: 'paymodex',
      displayName: 'Paymode-X',
      isRegistered: true,
      homeURL: 'https://secure.paymode.com/px/login',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/making-payments/integrated-payables.aspx',
      isDisabled: false
    },
    {
      name: 'retaillockbox',
      displayName: 'Retail Lockbox',
      isRegistered: false,
      homeURL: 'https://www.fislbxcentral.com/lms/',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/treasury-management/managing-receivables/lockbox.page',
      isDisabled: false
    },
    {
      name: 'sweepnet',
      displayName: 'SweepNet',
      isRegistered: true,
      homeURL: 'https://sweepnet.qacitizenscommercialbanking.com/LoginMain.aspx',
      learnMoreURL: 'https://www.citizensbank.com/small-business/cash-management/sweep-account.aspx?',
      isDisabled: false
    },
    {
      name: 'stuckynetlink',
      displayName: 'StuckyNet-Link',
      isRegistered: true,
      homeURL: 'https://snlnet.qacitizenscommercialbanking.com/SNL',
      learnMoreURL: 'https://www.citizensbank.com/corporate-finance/financing/asset-based-lending.aspx?',
      isDisabled: false
    }
  ],

  univ_smbUserLogin: [
    {
      name: 'User guide',
      displayName: 'User guide',
      isRegistered: true,
      description: 'Want a get more into the details?Our user guide has all the info you need.',
      homeURL: 'https://client.qacitizensbank.com/s/article/User-Guide',
      learnMoreURL: 'https://client.qacitizensbank.com/s/article/User-Guide',
      isDisabled: false,
      linkText: 'Download',
      src: `${assetURL}assets/docSmb.png`
    },
    {
      name: 'Quick Start guide',
      displayName: 'Quick Start guide',
      isRegistered: true,
      description: 'Want a fast-track your set-up? Our quick start guide can help you get up and running.',
      homeURL: 'https://client.qacitizensbank.com/s/article/Quick-Start-Guide',
      learnMoreURL: 'https://client.qacitizensbank.com/s/article/Quick-Start-Guide',
      isDisabled: false,
      linkText: 'Download',
      src: `${assetURL}assets/docSmb.png`
    },
    {
      name: 'Training Videos',
      displayName: 'Training Videos',
      isRegistered: true,
      description: 'Ours tool are simple to use, but if you need a hand, check out our training videos.',
      homeURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      learnMoreURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      isDisabled: false,
      linkText:'Watch Videos',
      src: `${assetURL}assets/videoSmb.png`
    },
    {
      name: 'FAQs',
      displayName: 'FAQs',
      isRegistered: false,
      description: 'Need help? Here are answers to some of our most frequently asked questions.',
      homeURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      learnMoreURL: 'https://client.qacitizensbank.com/s/knowledgearticledetail?type=CashFlowEssentials',
      isDisabled: false,
      linkText: 'FAQs',
      src: `${assetURL}assets/faqSmb.png`
    }
  ]
};
