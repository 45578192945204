<div id="headerContainer">
    <nav class="navbar navbar-expand-lg" id="{{ id }}">
        <span class="navbar-brand fixedMargin flex-shrink-1">
            <img alt="Citizens Commercial Banking" src="{{assetURL}}assets/CitizensCommercial_TM_Horz_RGB_HEX.svg">
        </span>
        <div class="collapse navbar-collapse" id="navbarNav justify-content-center">
            <div class="flex-fill"></div>
        </div>
        <div class="flex-fill"></div>
        <div class="flex-fill"></div>
        <div class="flex-fill empty1"></div>
        <!-- Digital Butler -->
        <div class="navbar-align">
            <ul class="navbar-nav" *ngIf="!isMobileApp">
                <li class="nav-item">
                    <button id="digitalButler" (click)="openUniversalLogin()" cbdata-reason="digital-butler"
                        cbdata-type="link" class="btn btn-primary butler-button cbensightenevent">
                        <span>
                            <img alt="sso-butler" class="sso-butler" src="{{assetURL}}assets/DB_Logo_White.svg">
                        </span>
                        Ask Digital Butler</button>
                </li>
            </ul>

            <ul class="navbar-nav" *ngIf="!isMobileApp && totalNotifications !== 0">
                <li class="nav-item">
                    <div class="dropdown float-right">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle cbensightenevent"
                            data-bs-toggle="dropdown" data-toggle="dropdown" id="svgBell" type="button" cbdata-reason="notifications-header" cbdata-type="button">Notifications
                        </button>
                        <app-loading-spinner [displaySpinner]="displaySpinner"></app-loading-spinner>

                        <span *ngIf="!displaySpinner" [ngClass]="{'zero-notifications': totalNotifications === 0}" class="badge-number">{{totalNotifications}}</span>

                        <div #notifications aria-labelledby="userName" class="dropdown-menu dropdown-menu-notification">

                            <div>
                                <b *ngIf="notificationsList?.length > 1">Company</b>
                                <select *ngIf="notificationsList?.length > 1" name="companyNameSelect"
                                    (change)="displayNotifications($event)" [(ngModel)]="selectedCompanyId">
                                    <option *ngFor="let creds of notificationsList" [value]="creds.compId">
                                        {{creds.compId}}</option>
                                </select>
                            </div>
                            <ul class="notifications-list">
                                <ng-container *ngFor="let item of selectedNotifications; let i = index;">
                                    <li class="notifications-count cbensightenevent" *ngIf="i < 3" (click)="openApplication(item)" cbdata-reason="notifications-header-login-to-accessoptima" cbdata-type="li">
                                        <span *ngIf="item.count !== 0" class="count">{{ item.count }}</span>
                                        <p *ngIf="item.count !== 0" class="notification-subject"
                                            [innerHTML]="item.subject"></p>
                                    </li>
                                    <li class="more-notifications" *ngIf="i === 3 && selectedNotifications.length > 3">
                                        <a (click)="viewMoreNotifications()" class="view-more">View More</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>


            <ul class="navbar-nav" id="desktopUser">
                <li class="nav-item float-right">
                    <div class="dropdown float-right">
                        <button aria-expanded="false" aria-haspopup="true" class="btn btn-primary dropdown-toggle"
                            data-bs-toggle="dropdown" data-toggle="dropdown" id="userName" type="button">{{ fullName !==
                            undefined ?
                            fullName : fullNameFromSession }}
                        </button>
                        <ul aria-labelledby="userName" class="dropdown-menu dropdown-menu-right">
                            <li><a (click)="profile()" (touchend)="profile()" cbdata-reason="dashboard-profile"
                                    cbdata-type="link" class="dropdown-item cbensightenevent">Update Profile</a></li>
                            <li *ngIf="!isMobileApp"><a (click)="manageCredentials()" (touchend)="manageCredentials()"
                                    cbdata-reason="dashboard-profile" cbdata-type="link"
                                    class="dropdown-item cbensightenevent">Manage
                                    Services</a>
                            </li>
                            <li><a (click)="logout()" (touchend)="logout()" cbdata-reason="dashboard-logout"
                                    cbdata-type="link" class="dropdown-item cbensightenevent">Sign out</a></li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

</div>

<ng-template #modal let-cross="close" let-dismiss="dismiss">
    <app-message-dialog [title]="" [cross]="cross" [dismiss]="dismiss" class="justify-content-center">
      <div class="boldDialogText">You have one active session in
        <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>.
      </div>
      <div>Please, log out from <span [ngClass]="{ 'registered': modalIsRegistered }">{{ modalDisplayName }}</span>
        and close the tab before accessing with a different credential.</div>
    </app-message-dialog>
</ng-template>

  <!-- new application tab container -->
  <ng-container #container></ng-container>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <app-alert></app-alert>
      </div>
    </div>
  </div>